import React, { useState, useLayoutEffect, useEffect } from 'react';
import {
  Area,
  ComposedChart,
  Line,
  ReferenceDot,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';
import AnimatedNumber from 'react-animated-number';

import styles from '../styles/ToForm.module.css';

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const ToForm = ({
  showButtons = false,
  price = { minimum: 2000, yours: 3500, maximum: 5000 },
  progress = 0,
}) => {
  const [width] = useWindowSize();
  const [myPrice, setMyPrice] = useState(0);
  const [priceMin, setPriceMin] = useState(0);
  const [priceMax, setPriceMax] = useState(0);
  const [referenceXAxis, setReferenceXAxis] = useState(0);
  const [pageTitle, setPageTitle] = useState('');

  useEffect(() => {
    let url = window.location.href;

    if (url.includes('sdb')) {
      document.title = 'Réussir sa salle de bain';
      setPageTitle('Réussir sa salle de bain');
    } else if (url.includes('habitat')) {
      document.title = 'Rénover son habitat';
      setPageTitle('Rénover son habitat');
    } else if (url.includes('maison')) {
      document.title = 'Aménagement de maison';
      setPageTitle('Aménagement de maison');
    } else if (url.includes('extension')) {
      document.title = 'Extension de maison';
      setPageTitle('Extension de maison');
    } else if (url.includes('combles')) {
      document.title = 'Aménagement combles';
      setPageTitle('Aménagement combles');
    } else {
      document.title = 'Aménagement combles';
      setPageTitle('Aménagement combles');
    }
  }, [pageTitle]);

  useEffect(() => {
    setReferenceXAxis(
      ((price.yours - price.minimum) / (price.maximum - price.minimum)) * 100
    );
    setMyPrice(price.yours);
    setPriceMin(price.minimum);
    setPriceMax(price.maximum);
  }, [price, width]);

  const renderGraph = () => {
    const graphWidth = width > 800 ? width / 3 : width > 500 ? 400 : 250;

    const data = [
      { x: 0, area: 40, lowLine: 0 },
      { x: 16.6, area: 50, lowLine: 0 },
      { x: 33.3, area: 70, lowLine: 0 },
      { x: 50, area: 100, lowLine: 0 },
      { x: 66.6, area: 140, lowLine: 0 },
      { x: 83.3, area: 190, lowLine: 0 },
      { x: 100, area: 250, lowLine: 0 },
    ];

    const getY = (x) => 0.0179704 * Math.pow(x, 2) + 0.30345 * x + 39.9915;

    return (
      <ResponsiveContainer width='100%' height='100%' minHeight={170}>
        <ComposedChart data={data} margin={{ left: 20, right: 20, top: 20 }}>
          <defs>
            <linearGradient id='grad' x1='0' y1='0' x2='1' y2='0'>
              <stop offset={`${referenceXAxis || 0}%`} stopColor='#F4C49D' />
              <stop offset={`${referenceXAxis || 0}%`} stopColor='#ffffff' />
            </linearGradient>
            <linearGradient id='line' x1='0' y1='0' x2='1' y2='0'>
              <stop offset={`${referenceXAxis || 0}%`} stopColor='#E46C0A' />
              <stop offset={`${referenceXAxis || 0}%`} stopColor='#F4C49D' />
            </linearGradient>
          </defs>
          <Area
            type='natural'
            dataKey='area'
            xAxisId='xAxis'
            yAxisId='yAxis'
            stroke='url(#line)'
            strokeWidth={graphWidth < 300 ? 3 : 5}
            fill='url(#grad)'
            fillOpacity={1}
            points={[{ x: 25, y: 40 }]}
          />
          <Line
            type='natural'
            dataKey='lowLine'
            stroke='#F4C49D'
            strokeWidth={graphWidth < 300 ? 5 : 10}
            xAxisId='xAxis'
            yAxisId='yAxis'
            dot={false}
          />
          <ReferenceLine
            xAxisId='xAxis'
            yAxisId='yAxis'
            segment={[
              { x: referenceXAxis <= 0 ? 0 : referenceXAxis, y: 0 },
              {
                x: referenceXAxis <= 0 ? 0 : referenceXAxis,
                y: referenceXAxis <= 0 ? 40 : getY(referenceXAxis),
              },
            ]}
            className={styles.cheapText}
            stroke='#E46C0A'
            strokeWidth={graphWidth < 300 ? 3 : 5}
          />
          <ReferenceDot
            xAxisId='xAxis'
            yAxisId='yAxis'
            x={referenceXAxis <= 0 ? 0 : referenceXAxis}
            y={referenceXAxis <= 0 ? 40 : getY(referenceXAxis)}
            r={graphWidth < 500 ? 10 : 15}
            stroke='#E46C0A'
            strokeWidth={graphWidth < 300 ? 3 : 5}
          />
          <XAxis type='number' dataKey='x' xAxisId='xAxis' hide />
          <YAxis type='number' dataKey='area' yAxisId='yAxis' hide />
        </ComposedChart>
      </ResponsiveContainer>
    );
  };

  const progressBar = () => (
    <div className={styles.progressBarContainer} style={{ height: 10 }}>
      <div
        style={{
          backgroundImage: `linear-gradient(to right, #E46C0A, #E46C0A ${progress * 100
            }%, #EBEBEB ${progress * 100}%, #EBEBEB)`,
          borderRadius: 5,
          color: '#E46C0A',
          maxHeight: 10,
          overflow: 'hidden',
        }}
      >
        {/* No idea why this part broke, so now it needs a text to render background image */}
        {progress > 0 ? '.' : null}
      </div>
    </div>
  );

  return (
    <div className={styles.container}>
      {showButtons || progressBar()}
      <div className={styles.secondContainer}>
        {showButtons ? (
          <div className={styles.textContainer}>
            <p className={styles.reallyBigTitle}>Rénover son habitat</p>
            <p className={styles.semiboldText}>
              Coût moyen {(price.minimum + price.maximum) / 2 || 0}€
            </p>
            <p className={styles.bigText}>
              {price.minimum || 0}€ - {price.maximum || 0}€
            </p>
            <p className={styles.orangeText}> Fourchette de dépense moyenne</p>
            {!showButtons || (
              <p className={styles.semiboldOrangeText}>Chiffrez vos travaux</p>
            )}
            {!showButtons || (
              <div className={styles.buttonContainer}>
                <input
                  className={styles.input}
                  placeholder={'Code postal*'}
                  type='number'
                />
                <button className={styles.continueButton}>CONTINUER</button>
              </div>
            )}
          </div>
        ) : (
          <div className={styles.textContainer2}>
            <p className={styles.reallyBigTitle}>{pageTitle}</p>
            <div className={styles.minusMargin}>
              <p className={styles.text1}>Coût estimé* de vos travaux</p>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <AnimatedNumber
                  className={styles.reallyBigTitle}
                  style={{ color: '#E46C0A' }}
                  value={myPrice}
                  formatValue={(v) =>
                    v.toLocaleString('fr', { maximumFractionDigits: 0 })
                  }
                />
                <p
                  className={styles.reallyBigTitle}
                  style={{ color: '#E46C0A' }}
                >
                  {' '}
                  €
                </p>
              </div>
              <p className={styles.oneRemText}>
                * Sur une fourchette de dépense moyenne
              </p>
            </div>
          </div>
        )}
        <div className={styles.graphContainer}>{renderGraph()}</div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <div
          className={styles.priceContainer}
          style={{ flex: width >= 992 ? 2 / 5 : 1 }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyItems: 'center',
              marginLeft: width >= 992 ? 20 : 0,
            }}
          >
            <p className={styles.priceText}>Bas</p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#3F2406',
              }}
            >
              <AnimatedNumber
                className={styles.priceText2}
                style={{ fontWeight: 'bold', margin: 0 }}
                value={priceMin}
                formatValue={(v) =>
                  v.toLocaleString('fr', { maximumFractionDigits: 0 })
                }
              />
              <p
                className={styles.priceText2}
                style={{ fontWeight: 'bold', margin: 0 }}
              >
                €
              </p>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyItems: 'center',
            }}
          >
            <p className={styles.priceText}>Estimation </p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#3F2406',
              }}
            >
              <AnimatedNumber
                className={styles.priceText2}
                style={{ fontWeight: 'bold', margin: 0 }}
                value={myPrice}
                formatValue={(v) =>
                  v.toLocaleString('fr', { maximumFractionDigits: 0 })
                }
              />
              <p
                className={styles.priceText2}
                style={{ fontWeight: 'bold', margin: 0 }}
              >
                €
              </p>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyItems: 'center',
            }}
          >
            <p className={styles.priceText}>Haut</p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                color: '#3F2406',
              }}
            >
              <AnimatedNumber
                className={styles.priceText2}
                style={{ fontWeight: 'bold', margin: 0 }}
                value={priceMax}
                formatValue={(v) =>
                  v.toLocaleString('fr', { maximumFractionDigits: 0 })
                }
              />
              <p
                className={styles.priceText2}
                style={{ fontWeight: 'bold', margin: 0 }}
              >
                €
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToForm;
