import React, { useEffect, useState } from "react";
import isEmail from "validator/lib/isEmail";

import ToForm from "../components/ToForm";
import styles from "../styles/FormPage.module.css";
import getQuestions from "../util/getQuestions";
import calculatePrice from "../util/calculatePrice";
import OutsideAlerter from "../components/OutsideAlerter";
import { ReactComponent as X } from "../images/x_svg.svg";
import { ReactComponent as NextArrow } from "../images/arrow-forward-outline.svg";
import { ReactComponent as PrevArrow } from "../images/arrow-back-outline.svg";
import { ReactComponent as SendIcon } from "../images/send-4008.svg";
import { ReactComponent as CheckIcon } from "../images/check.svg";

const HomePage = () => {
  const [questionType, setQuestionType] = useState();
  const [price, setPrice] = useState({ cheap: 0, average: 0, expensive: 0 });
  const [questions, setQuestions] = useState([]);
  const [goodQuestions, setGoodQuestions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalSendMessage, setModalSendMessage] = useState(false);
  const [page, setPage] = useState(0);

  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [description, setDescription] = useState("");
  const [origin, setOrigin] = useState("");
  const [checkbox, setCheckbox] = useState(false);

  const [lastNameError, setLastNameError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [postalCodeError, setPostalCodeError] = useState();
  const [emailError, setEmailError] = useState();
  const [telError, setTelError] = useState(false);
  const [descriptionError, setDescriptionError] = useState();

  const [startFormEvent, setStartFormEvent] = useState(false);
  const [questionNotSent, setQuestionNotSent] = useState(undefined);
  const [startQuotationEvent, setStartQuotationEvent] = useState(false);
  const [completeQuotationEvent, setCompleteQuotationEvent] = useState(false);

  useEffect(() => {
    let url = window.location.href;

    if (url.includes("sdb")) {
      document.title = "Réussir sa salle de bain";
      setQuestionType("bathroom");
      setOrigin("web_blogsalledebain");
    } else if (url.includes("habitat")) {
      document.title = "Rénover son habitat";
      setQuestionType("interior");
      setOrigin("web_bloghabitat");
    } else if (url.includes("maison")) {
      document.title = "Aménagement de maison";
      setQuestionType("interior");
      setOrigin("web_blogamenagement");
    } else if (url.includes("extension")) {
      document.title = "Extension de maison";
      setQuestionType("extension");
      setOrigin("web_blogextension");
    } else if (url.includes("combles")) {
      document.title = "Aménagement combles";
      setQuestionType("attic");
      setOrigin("web_blogcombles");
    } else {
      document.title = "Aménagement combles";
      setQuestionType("attic");
      setOrigin("web_blogcombles");
    }

    if (questionType !== undefined) {
      getQuestions(questionType).then((questions) => setQuestions(questions));
    }
  }, [questionType]);

  //Logic behind the questions
  useEffect(() => {
    setQuestions(questions);
    if (questions !== undefined) {
      const flatQuestions = questions.flat();
      const qArray = [];

      const correctQuestions = flatQuestions.filter((question) => {
        const parentQ = flatQuestions.filter(
          (q) => q.id === question.parentQuestionId
        );
        if (parentQ[0]?.answer !== undefined) {
          if (
            parentQ[0].answer === "0" ||
            parentQ[0].answer === "NON" ||
            parentQ[0].answer?.[0] === "Aucun"
          ) {
            return false;
          }
          return true;
        }
        if (question.parentQuestionId === null) {
          return true;
        }
        return false;
      });

      for (let i = 0; i < correctQuestions.length; i += 2) {
        qArray.push(correctQuestions.slice(i, i + 2));
      }

      setGoodQuestions(qArray);
    }

    setPrice(calculatePrice(questions, questionType));
  }, [questions, questionType]);

  const nextPage = () => {
    if (page === goodQuestions.length - 1) {
      setShowModal(true);

      if (!completeQuotationEvent) {
        window.dataLayer.push({
          value: price.yours,
          event: 'complete_quotation_flow',
        })

        setCompleteQuotationEvent(true);
      }

      return;
    }
    setPage(page + 1);
  };

  const prevPage = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    if (modalSendMessage) {
      setTimeout(() => {
        setShowModal(false);
        setModalSendMessage(false);
      }, 50000);
    }
  }, [modalSendMessage]);

  const handleKeyPress = (e, question) => {
    if (e.key === "Enter" && goodQuestions[page][1].id === question.id) {
      nextPage();
    }
  };

  const renderNewQuestions = (questions = []) => {
    const form = questions.map((question) => {
      if (question.questionType === "MULTIPLECHOICE") {
        return renderNewMultiple(question);
      } else {
        return renderNewInput(question);
      }
    });

    return form;
  };

  const renderNewMultiple = (question) => {
    let hide = false;
    if (
      goodQuestions[page]?.[1]?.id === question.id &&
      (goodQuestions[page]?.[0]?.answer === undefined ||
        goodQuestions[page]?.[0]?.answer === "" ||
        goodQuestions[page]?.[0]?.answer?.[0] === "Aucun" ||
        goodQuestions[page]?.[0]?.answer.length === 0)
    ) {
      hide = true;
    }

    if (question.extra === "multiple") {
      question.multiple = question.multiple || [];
      question.open = question.open || false;

      return (
        <div
          key={question.id}
          className={
            hide
              ? styles.questionContainerHidden
              : styles.questionContainerVisible
          }
          style={{ width: "100%" }}
        >
          <p className={styles.questionText}>{question.text}</p>
          <div
            tabIndex='0'
            className={styles.buttonContainer}
            style={{ position: "relative" }}
            onBlur={() => {
              question.open = false;
              question.answer = question.multiple;
              setQuestions([...questions]);
            }}
          >
            <div
              className={
                question.multiple.length !== 0
                  ? styles.dropUp
                  : styles.dropUpGrey
              }
              readOnly
              onClick={() => {
                question.open = true;
                setQuestions([...questions]);
              }}
            >
              {question.multiple.length !== 0
                ? question.multiple.join(", ")
                : "Les éléments"}
            </div>
            {question.open && (
              <div className={styles.dropUpContainer}>
                <div
                  className={styles.textHover}
                  onClick={(e) => {
                    question.multiple = question.multiple?.includes("Aucun")
                      ? question.multiple.filter((q) => q === "Aucun")
                      : ["Aucun"];
                    question.answer = question.multiple;
                    question.open = false;
                    setQuestions([...questions]);

                    if (questionNotSent) {
                      window.dataLayer.push({
                        value: price.yours,
                        answer: questionNotSent.answer,
                        question: questionNotSent.text,
                        event: 'progress_in_quotation_flow',
                      })

                      setQuestionNotSent(undefined);
                    }

                    const cost = calculatePrice(questions, questionType);
                    setPrice(cost);

                    window.dataLayer.push({
                      value: cost.yours,
                      answer: question.answer,
                      question: question.text,
                      event: 'progress_in_quotation_flow',
                    })

                    if (
                      question.id === goodQuestions[page]?.[1]?.id &&
                      goodQuestions[page]?.[1].answer === ["Aucun"]
                    ) {
                      nextPage();
                    }
                    if (
                      goodQuestions[page].length === 1 &&
                      goodQuestions[page]?.[0].answer !== undefined
                    ) {
                      nextPage();
                    }
                  }}
                >
                  <p className={styles.dropUpText}>Aucun</p>
                  {question.multiple?.includes("Aucun") && (
                    <CheckIcon className={styles.dropUpCheck} />
                  )}
                </div>
                {question.choices.map((choice) => (
                  <div
                    key={choice.text}
                    className={styles.textHover}
                    onClick={(e) => {
                      question.multiple = question.multiple?.includes(
                        choice.text
                      )
                        ? question.multiple.filter((q) => q !== choice.text)
                        : [...question.multiple, choice.text].filter(
                          (q) => q !== "Aucun"
                        );
                      setQuestions([...questions]);

                      if (questionNotSent) {

                        window.dataLayer.push({
                          value: price.yours,
                          answer: questionNotSent.answer,
                          question: questionNotSent.text,
                          event: 'progress_in_quotation_flow',
                        })

                        setQuestionNotSent(undefined);
                      }

                      const cost = calculatePrice(questions, questionType);
                      setPrice(cost);

                      window.dataLayer.push({
                        value: cost.yours,
                        answer: question.answer,
                        question: question.text,
                        event: 'progress_in_quotation_flow',
                      })

                      if (
                        question.id === goodQuestions[page]?.[1]?.id &&
                        question.answer !== undefined &&
                        question.answer !== goodQuestions[page]?.[1]?.answer
                      ) {
                        nextPage();
                      }
                      if (
                        goodQuestions[page].length === 1 &&
                        goodQuestions[page]?.[0].answer !== undefined &&
                        question.answer?.[0] !==
                        goodQuestions[page]?.[0]?.answer?.[0]
                      ) {
                        nextPage();
                      }
                    }}
                  >
                    <p className={styles.dropUpText}>{choice.text}</p>
                    {question.multiple?.includes(choice.text) && (
                      <CheckIcon className={styles.dropUpCheck} />
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      );
    }

    return (
      <div
        key={question.id}
        className={
          hide
            ? styles.questionContainerHidden
            : styles.questionContainerVisible
        }
      >
        <p className={styles.questionText}>{question.text}</p>
        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          {question.choices.map((choice) => (
            <button
              key={choice.id}
              className={
                question.choices.length !== 2 ||
                  question.choices[0].text !== "OUI"
                  ? question.answer === choice.text
                    ? styles.multipleFilled
                    : styles.multipleNotFilled
                  : question.answer === choice.text
                    ? styles.yesNoButtonFilled
                    : styles.yesNoButtonNotFilled
              }
              onClick={(e) => {
                question.answer = choice.text;
                setQuestions([...questions]);

                if (questionNotSent) {

                  window.dataLayer.push({
                    value: price.yours,
                    answer: questionNotSent.answer,
                    question: questionNotSent.text,
                    event: 'progress_in_quotation_flow',
                  })

                  setQuestionNotSent(undefined);
                }

                const cost = calculatePrice(questions, questionType);
                setPrice(cost);

                window.dataLayer.push({
                  value: cost.yours,
                  answer: question.answer,
                  question: question.text,
                  event: 'progress_in_quotation_flow',
                })

                if (question.id === goodQuestions[page]?.[1]?.id) {
                  nextPage();
                }
                if (
                  goodQuestions[page].length === 1 &&
                  goodQuestions[page]?.[0].answer !== "OUI"
                ) {
                  nextPage();
                }

                if (!startQuotationEvent) {
                  setStartQuotationEvent(true)

                  window.dataLayer.push({
                    event: 'start_quotation_flow',
                  })
                }
              }}
            >
              {choice.text}
            </button>
          ))}
        </div>
      </div>
    );
  };

  const renderNewInput = (question) => {
    let hide = false;
    if (
      goodQuestions[page]?.[1]?.id === question.id &&
      (goodQuestions[page]?.[0]?.answer === undefined ||
        goodQuestions[page]?.[0]?.answer === "" ||
        goodQuestions[page]?.[0]?.answer?.[0] === "Aucun")
    ) {
      hide = true;
    }

    return (
      <div
        key={question.id}
        className={
          hide
            ? styles.questionContainerHidden
            : styles.questionContainerVisible
        }
      >
        <p className={styles.questionText}>{question.text}</p>
        <div className={styles.buttonContainer}>
          <input
            className={styles.input}
            onKeyPress={(e) => handleKeyPress(e, question)}
            value={question.answer || ""}
            onChange={(e) => {
              question.answer =
                e.target.value === "00" ||
                  e.target.value === "01" ||
                  e.target.value === "02" ||
                  e.target.value === "03" ||
                  e.target.value === "04" ||
                  e.target.value === "05" ||
                  e.target.value === "06" ||
                  e.target.value === "07" ||
                  e.target.value === "08" ||
                  e.target.value === "09"
                  ? "0"
                  : e.target.value;
              setQuestions([...questions]);
              setPrice(calculatePrice(questions, questionType));

              setQuestionNotSent(question);

              e.target.value === "0" &&
                goodQuestions[page][1].id === question.id &&
                nextPage();
            }}
            type='number'
            onWheel={(e) => e.target.blur()}
            placeholder={
              !question.extra
                ? "unités"
                : question.extra === "meters"
                  ? "m"
                  : "m²"
            }
            onFocus={() => {
              if (!startQuotationEvent) {
                setStartQuotationEvent(true)

                window.dataLayer.push({
                  event: 'start_quotation_flow',
                })
              }
            }
            }
          />
          {!question.extra ? null : question.extra === "meters" ? (
            <p className={styles.m2}>m</p>
          ) : (
            <p className={styles.m2}>m²</p>
          )}
        </div>
      </div>
    );
  };

  const progressMade = goodQuestions?.length
    ? page / (goodQuestions.length - 1)
    : 0;

  const disableNext = () => {
    if (goodQuestions[page]?.length === 1) {
      return !(
        goodQuestions[page]?.[0]?.answer !== undefined &&
        goodQuestions?.length > page
      );
    }

    if (typeof goodQuestions[page]?.[1]?.answer === "object") {
      return !(
        goodQuestions[page]?.[0]?.answer !== undefined &&
        goodQuestions[page]?.[1]?.answer !== undefined &&
        goodQuestions?.length > page &&
        goodQuestions[page]?.[1]?.answer.length !== 0
      );
    }

    return !(
      goodQuestions[page]?.[0]?.answer !== undefined &&
      goodQuestions[page]?.[1]?.answer !== undefined &&
      goodQuestions?.length > page
    );
  };

  const handleSubmit = () => {
    let error = false;

    if (firstName === "") {
      setNameError(true);
      error = true;
    }

    if (lastName === "") {
      setLastNameError(true);
      error = true;
    }

    if (postalCode.length !== 5 && postalCodeError !== "") {
      setPostalCodeError("Code postal invalide.");
      error = true;
    }

    if (postalCode === "") {
      setPostalCodeError("Ce champ est obligatoire.");
      error = true;
    }

    if (!isEmail(email)) {
      setEmailError("Adresse e-mail invalide..");
      error = true;
    }

    if (email === "") {
      setEmailError("Ce champ est obligatoire.");
      error = true;
    }

    if (description === "") {
      setDescriptionError("Ce champ est obligatoire.");
      error = true;
    }

    if (description.length <= 10) {
      setDescriptionError(
        "Le message doit être composé d'au moins 10 caractères."
      );
      error = true;
    }

    if (tel !== "" && tel.length !== 10) {
      setTelError(true);
      error = true;
    }

    if (error) {
      return;
    }

    let answers = description;
    questions
      .flat()
      .map(
        (question) =>
        (answers =
          answers +
          "\n" +
          question.text +
          "\n" +
          (question.answer === undefined ? "N/A" : question.answer) +
          "\n")
      );

    const prod = "https://galaxy.lamaisondestravaux.com/galaxy/new_request";
    // const test = 'https://galaxy-test.lamaisondestravaux.com/galaxy/new_request'

    if (checkbox)
      window.dataLayer.push({
        event: 'sign_up',
        type: 'newsletter',
      })

    fetch(prod, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        params: {
          token: "dyArvXNcOjsgWmV",
          jobify: "False",
          nom: lastName,
          prenom: firstName,
          adresse: address,
          CP: postalCode,
          email: email,
          tel: tel,
          descriptif: answers,
          subscribe: checkbox ? 1 : 0,
          origine: origin,
        },
      }),
    })
      .then(() => {
        setModalSendMessage(true)

        window.dataLayer.push({
          event: 'submit_form',
          form_name: 'contact',
        })
        window.dataLayer.push({
          value: price.yours,
          event: 'generate_lead',
        })
      })
      .catch((e) => console.log(e));
  };

  return (
    <div className={styles.bigContainer}>
      <ToForm
        price={price}
        showButtons={false}
        progress={progressMade}
        setShowModal={setShowModal}
      />
      <div className={styles.newContainer}>
        <div style={{ alignSelf: "flex-start" }}>
          {renderNewQuestions(goodQuestions[page])}
        </div>
        <div className={styles.newContainerButton}>
          <div className={styles.newContainer2}>
            <button
              className={styles.prevButton}
              disabled={page === 0}
              onClick={() => {
                prevPage();
              }}
            >
              <PrevArrow height={25} />
            </button>
            <button
              className={styles.contactUs}
              disabled={disableNext()}
              onClick={() => {
                nextPage();
              }}
            >
              {page === goodQuestions.length - 1 ? (
                <SendIcon />
              ) : (
                <NextArrow height={25} />
              )}
            </button>
          </div>
        </div>
      </div>
      {showModal && (
        <OutsideAlerter
          setInvisible={setShowModal}
          setMessageInvisible={setModalSendMessage}
        >
          {modalSendMessage ? (
            <div className={styles.modalSmall}>
              <X
                className={styles.X}
                onClick={() => {
                  setShowModal(false);
                  setModalSendMessage(false);
                }}
              />
              <p className={styles.modalSmallText}>
                Merci de nous avoir contacté ! Nous reviendrons vers vous dès
                que possible.
              </p>
            </div>
          ) : (
            <div className={styles.modal}>
              <X className={styles.X} onClick={() => setShowModal(false)} />
              <h2>
                <span>Nous Contacter</span>
              </h2>
              <div className={styles.formInput}>
                <input
                  className={styles.modalInput}
                  placeholder='Nom*'
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                    setLastNameError(false);
                  }}
                  onFocus={() => {
                    if (!startFormEvent) {
                      setStartFormEvent(true)

                      window.dataLayer.push({
                        event: 'start_form',
                        form_name: 'contact',
                      })
                    }
                  }}
                />
                <p
                  className={styles.errorMessage}
                  style={{ visibility: lastNameError ? "unset" : "hidden" }}
                >
                  Ce champ est obligatoire.
                </p>
              </div>
              <div className={styles.formInput}>
                <input
                  className={styles.modalInput}
                  placeholder='Prénom*'
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                    setNameError(false);
                  }}
                  onFocus={() => {
                    if (!startFormEvent) {
                      setStartFormEvent(true)

                      window.dataLayer.push({
                        event: 'start_form',
                        form_name: 'contact',
                      })
                    }
                  }}
                />
                <p
                  className={styles.errorMessage}
                  style={{ visibility: nameError ? "unset" : "hidden" }}
                >
                  Ce champ est obligatoire.
                </p>
              </div>
              <div className={styles.formInput}>
                <input
                  id='PostalCode'
                  className={styles.modalInput}
                  placeholder='Code Postal*'
                  type='text'
                  value={postalCode}
                  maxLength={5}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setPostalCode(e.target.value);
                    }
                    setPostalCodeError();
                  }}
                  onFocus={() => {
                    if (!startFormEvent) {
                      setStartFormEvent(true)

                      window.dataLayer.push({
                        event: 'start_form',
                        form_name: 'contact',
                      })
                    }
                  }}
                />
                <p
                  className={styles.errorMessage}
                  style={{ visibility: postalCodeError ? "unset" : "hidden" }}
                >
                  {postalCodeError}
                </p>
              </div>
              <div className={styles.formInput}>
                <input
                  className={styles.modalInput}
                  placeholder='Adresse e-mail*'
                  value={email}
                  min='0'
                  max='99999'
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError();
                  }}
                  onFocus={() => {
                    if (!startFormEvent) {
                      setStartFormEvent(true)

                      window.dataLayer.push({
                        event: 'start_form',
                        form_name: 'contact',
                      })
                    }
                  }}
                />
                <p
                  className={styles.errorMessage}
                  style={{ visibility: emailError ? "unset" : "hidden" }}
                >
                  {emailError}
                </p>
              </div>
              <div className={styles.formInput}>
                <input
                  id='PostalCodeInput'
                  type='tel'
                  className={styles.modalInput}
                  placeholder='Téléphone'
                  value={tel}
                  onChange={(e) => {
                    const re = /^[0-9\b]+$/;
                    if (e.target.value === "" || re.test(e.target.value)) {
                      setTel(e.target.value);
                    }
                    setTelError(false);
                  }}
                  onFocus={() => {
                    if (!startFormEvent) {
                      setStartFormEvent(true)

                      window.dataLayer.push({
                        event: 'start_form',
                        form_name: 'contact',
                      })
                    }
                  }}
                />
                <p
                  className={styles.errorMessage}
                  style={{ visibility: telError ? "unset" : "hidden" }}
                >
                  Ce numéro de téléphone est invalide.
                </p>
              </div>
              <div className={styles.formInput}>
                <input
                  className={styles.modalInput}
                  placeholder='Adresse'
                  value={address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                  onFocus={() => {
                    if (!startFormEvent) {
                      setStartFormEvent(true)

                      window.dataLayer.push({
                        event: 'start_form',
                        form_name: 'contact',
                      })
                    }
                  }}
                />
                <p
                  className={styles.errorMessage}
                  style={{ visibility: "hidden" }}
                >
                  Ce champ est obligatoire.
                </p>
              </div>
              <div style={{ width: "100%", marginRight: 10 }}>
                <textarea
                  className={styles.highInput}
                  placeholder='Votre message*'
                  style={{ resize: "none" }}
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                    setDescriptionError();
                  }}
                  onFocus={() => {
                    if (!startFormEvent) {
                      setStartFormEvent(true)

                      window.dataLayer.push({
                        event: 'start_form',
                        form_name: 'contact',
                      })
                    }
                  }}
                />
                {descriptionError ? (
                  <p className={styles.errorMessage}>{descriptionError}</p>
                ) : (
                  <p
                    className={styles.errorMessage}
                    style={{ color: "transparent" }}
                  >
                    .
                  </p>
                )}
              </div>
              <div
                className={styles.formInput}
                style={{
                  display: "flex",
                  alignItems: "center",
                  alignSelf: "flex-start",
                  height: 16,
                  marginTop: 0,
                }}
              >
                <input
                  type='checkbox'
                  value={checkbox}
                  onChange={() => setCheckbox(!checkbox)}
                  onFocus={() => {
                    if (!startFormEvent) {
                      setStartFormEvent(true)

                      window.dataLayer.push({
                        event: 'start_form',
                        form_name: 'contact',
                      })
                    }
                  }}
                />
                <label
                  style={{ marginRight: 5 }}
                  className={styles.checkboxText}
                >
                  S'abonner à la newsletter
                </label>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  className={styles.modalButton}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Envoyer
                </button>
              </div>
            </div>
          )}
        </OutsideAlerter>
      )}
    </div>
  );
};

export default HomePage;
